// 秒杀专区
import * as API from '@/api/index'
export default {
    /**  秒杀列表*/
    GetSecKill: params => {
        return API.POST('api/SecKill/getSecKill', params)
    },
    /**  商品列表*/
    GoodAll: params => {
        return API.POST('api/Commodity/all', params)
    },
    /**  商品分类*/
    GoodType: () => {
        return API.POST('api/Commodity/GoodType')
    },
    /**  线上分类 - 排除没有子类的分类*/
    AppletGoodsCategoryValid: params => {
        return API.POST('api/appletGoodsCategory/valid', params)
    },
    /**  会员等级*/
    CustomerLevel: params => {
        return API.POST('api/customerLevel/all', params)
    },
    /**  新增秒杀*/
    AddSecKill: params => {
        return API.POST('api/SecKill/addSecKill', params)
    },
    /**  编辑秒杀*/
    UpdateSecKill: params => {
        return API.POST('api/SecKill/updateSecKill', params)
    },
    /**  获取商品信息*/
    GoodInfo: params => {
        return API.POST('api/Goods/allGoods', params)
    },
}