<template>
  <div class="Seckill content_body">
    <!-- 搜索框 -->
    <div class="nav_header">
      <el-row>
        <el-col :span="20">
          <el-form
            :inline="true"
            size="small"
            class="demo-form-inline"
            @keyup.enter.native="handleSearch"
          >
            <el-form-item label="搜索">
              <el-input
                v-model="couponName"
                @clear="handleSearch"
                clearable
                placeholder="输入线上名称搜索"
              ></el-input>
            </el-form-item>
            <el-form-item label="状态">
              <el-select
                @change="handleSearch"
                v-model="couponActive"
                placeholder="请选择"
                clearable
              >
                <el-option label="未开始" :value="1"></el-option>
                <el-option label="进行中" :value="2"></el-option>
                <el-option label="已结束" :value="3"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="handleSearch">搜索</el-button>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="4" class="text_right">
          <el-button
            type="primary"
            size="small"
            @click="handleSeckill(null, 'new')"
            >新增</el-button
          >
        </el-col>
      </el-row>
    </div>
    <!-- 表格 -->
    <div>
      <el-table
        size="small"
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="light"
      >
        <el-table-column prop="ID" label="活动主图缩略">
          <template slot-scope="scope">
            <el-image
              style="width: 35px; height: 35px"
              :src="scope.row.activityImg"
              fit="fill"
            >
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="Title" label="线上名称"></el-table-column>
        <el-table-column prop="offlineType" label="线下类型"></el-table-column>
        <el-table-column prop="offlineName" label="线下名称"></el-table-column>
        <el-table-column
          prop="activityTime"
          label="活动时间"
          width="300px"
          :formatter="dateTimeFormat"
        ></el-table-column>
        <el-table-column prop="status" label="状态"></el-table-column>
        <el-table-column label="操作" width="180px">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="small"
              @click="handleSeckill(scope.row, 'edit')"
              v-prevent-click
              >编辑</el-button
            >
            <el-button
              type="primary"
              size="small"
              @click="handleSeckill(scope.row, 'copy')"
              v-prevent-click
              >复制</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <div class="pad_10 dis_flex flex_x_end" v-if="paginations.total > 0">
      <el-pagination
        background
        :current-page.sync="paginations.page"
        :layout="paginations.layout"
        :total="paginations.total"
        @current-change="currentChange"
      ></el-pagination>
    </div>
    <!-- 新增弹出层 -->
    <el-dialog
      :title="DialogTitle"
      :visible.sync="dialogVisible"
      width="50%"
      @closed="dialogClose"
    >
      <el-tabs v-model="activeName">
        <el-tab-pane label="基础信息" name="0">
          <el-scrollbar class="el_scrollbar_height from">
            <el-form
              :model="ruleForm"
              :rules="rules"
              ref="ruleForm"
              label-width="120px"
              class="demo-ruleForm"
              size="small"
            >
              <el-form-item prop="tableData">
                <span slot="label" style="color: #606266"
                  ><span style="color: #f56c6c; margin-right: 3px">*</span
                  >活动商品</span
                >
                <el-button
                  v-if="!ruleForm.tableData.length"
                  @click="selShopClick"
                  :show-message="ruleForm.tableData.length ? false : true"
                  class="marbm_10"
                  type="primary"
                  size="small"
                  >选择商品</el-button
                >
                <el-table
                  v-if="ruleForm.tableData.length"
                  :data="ruleForm.tableData"
                  style="width: 90%"
                  size="small"
                >
                  <el-table-column label="商品" width="250px">
                    <template slot-scope="scope">
                      <el-image
                        style="
                          width: 35px;
                          height: 35px;
                          vertical-align: middle;
                        "
                        :src="scope.row.Image[0].ImgURL"
                        fit="fit"
                      >
                      </el-image>
                      <span class="marlt_10">{{ scope.row.OnlineName }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="GoodsTypeName" label="商品类型">
                  </el-table-column>
                  <el-table-column prop="Price" label="销售价格">
                  </el-table-column>
                  <el-table-column label="操作">
                    <template slot-scope="scope">
                      <el-button type="danger" size="small" @click="deleteGoods"
                        >删除</el-button
                      >
                    </template>
                  </el-table-column>
                </el-table>
              </el-form-item>
              <el-form-item label="秒杀价格" prop="Price">
                <el-col :span="5">
                  <el-input placeholder="请输入" v-model="ruleForm.Price">
                    <template slot="append">元</template>
                  </el-input>
                </el-col>
              </el-form-item>
              <el-form-item label="活动时间" prop="activityTime">
                <el-date-picker
                  v-model="ruleForm.activityTime"
                  type="datetimerange"
                  align="right"
                  size="small"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :default-time="['08:00:00', '17:00:00']"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="秒杀库存">
                <el-col :span="8">
                  <el-radio-group v-model="ruleForm.inventoryBool">
                    <el-radio :label="false">不限制库存</el-radio>
                    <el-radio :label="true">限制库存</el-radio>
                  </el-radio-group>
                </el-col>
                <el-col :span="4" v-if="ruleForm.inventoryBool">
                  <el-input
                    placeholder="请输入"
                    v-model="ruleForm.inventory"
                  ></el-input>
                </el-col>
              </el-form-item>
              <el-form-item label="每人最多购买">
                <el-col :span="7">
                  <el-radio-group v-model="ruleForm.buyLimitBool">
                    <el-radio :label="false">不限购</el-radio>
                    <el-radio :label="true">限购</el-radio>
                  </el-radio-group>
                </el-col>
                <el-col :span="4" v-if="ruleForm.buyLimitBool">
                  <el-input
                    placeholder="请输入"
                    v-model="ruleForm.buyLimit"
                  ></el-input>
                </el-col>
              </el-form-item>
              <el-form-item label="活动限制">
                <el-radio-group v-model="ruleForm.restrictBool">
                  <el-radio :label="false">所有客户均可参与</el-radio>
                  <el-radio :label="true">指定客户可参与</el-radio>
                </el-radio-group>
                <div>
                  <el-checkbox-group
                    v-if="ruleForm.restrictBool"
                    v-model="ruleForm.checkList"
                  >
                    <el-checkbox
                      v-for="(item, index) in CustomerLevel"
                      :key="index"
                      :label="item.ID"
                      >{{ item.Name }}</el-checkbox
                    >
                  </el-checkbox-group>
                </div>
              </el-form-item>
            </el-form>
          </el-scrollbar>
        </el-tab-pane>
        <el-tab-pane label="上架网店" name="1">
          <el-scrollbar class="el_scrollbar_height from">
            <el-tree
              class="padtp_10"
              show-checkbox
              :expand-on-click-node="false"
              node-key="ID"
              :data="entityList"
              :default-expanded-keys="[1]"
              :props="defaultProps"
            >
              <span slot-scope="{ node, data }">
                <span class="font_14">{{ data.EntityName }}</span>
                <el-tag
                  class="marlt_5"
                  type="info"
                  size="mini"
                  v-if="data.IsStore"
                  >门店</el-tag
                >
                <el-tag
                  class="marlt_5"
                  type="info"
                  size="mini"
                  v-if="data.IsWarehouse"
                  >仓库</el-tag
                >
              </span>
            </el-tree>
          </el-scrollbar>
        </el-tab-pane>
      </el-tabs>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="confirm"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 新增选择商品弹出层 -->
    <el-dialog
      :title="selShopDialogTitle"
      :visible.sync="selShopDialogVisible"
      width="80%"
      @closed="dialogClose('goods')"
    >
      <!-- 搜索框 -->
      <el-row>
        <el-col :span="22">
          <el-form
            :inline="true"
            size="small"
            label-width="80px"
            class="demo-form-inline yhForm"
            @keyup.enter.native="selGoodsSearch"
          >
            <el-form-item label="线上名称">
              <el-input
                v-model="OnlineName"
                @clear="selGoodsSearch"
                clearable
                placeholder="输入商品线上名称搜索"
              ></el-input>
            </el-form-item>
            <el-form-item label="线下名称">
              <el-input
                v-model="OfflineName"
                @clear="selGoodsSearch"
                clearable
                placeholder="输入商品线下名称搜索"
              ></el-input>
            </el-form-item>
            <el-form-item label="商品类型">
              <el-select
                @change="selGoodsSearch"
                v-model="GoodsType"
                placeholder="请选择"
                clearable
              >
                <el-option
                  v-for="(item, index) in GoodType"
                  :key="index"
                  :label="item.Name"
                  :value="item.GoodsTypeID"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="线上分类">
              <el-cascader
                clearable
                v-model="CategoryID"
                :options="GoodsCategoryValid"
                :props="{ expandTrigger: 'hover' }"
                @change="selGoodsSearch"
              ></el-cascader>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="2">
          <el-button size="small" type="primary" @click="selGoodsSearch"
            >搜索</el-button
          >
        </el-col>
      </el-row>
      <el-scrollbar class="el_scrollbar_height">
        <!-- 表格 -->
        <div>
          <el-table
            size="small"
            ref="multipleTable"
            :data="selTableData"
            tooltip-effect="light"
          >
            <el-table-column prop="ID" label="主图缩略">
              <template slot-scope="scope">
                <el-image
                  style="width: 35px; height: 35px"
                  :src="scope.row.Image[0].ImgURL"
                  fit="fit"
                >
                </el-image>
              </template>
            </el-table-column>
            <el-table-column
              prop="OnlineName"
              label="线上别名"
            ></el-table-column>
            <el-table-column prop="Price" label="售价（元）"></el-table-column>
            <el-table-column
              prop="CategoryName"
              label="线上分类"
            ></el-table-column>
            <el-table-column prop="Sequence" label="线上排序"></el-table-column>
            <el-table-column
              prop="GoodsTypeName"
              label="线下类型"
            ></el-table-column>
            <el-table-column
              prop="OfflineName"
              label="线下名称"
            ></el-table-column>
            <el-table-column label="操作" width="100px">
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  size="small"
                  @click="selGoods(scope.row)"
                  v-prevent-click
                  >选择</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-scrollbar>
      <!-- 页码 -->
      <div class="pad_10 dis_flex flex_x_end">
        <el-pagination
          background
          :current-page.sync="Goodspaginations.page"
          :layout="Goodspaginations.layout"
          :total="Goodspaginations.total"
          @current-change="GoodsPageChange"
        ></el-pagination>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import API from "@/api/Marketing/MarketingPlay/Seckill";
import dateTime from "@/components/js/date";
export default {
  name: "Seckill",
  /**  引入的组件  */
  components: {},
  /**  Vue 实例的数据对象**/
  data() {
    return {
      couponName: "", //线上名称
      couponActive: "", //状态
      tableData: [], //表格数据
      paginations: {
        total: "",
        layout: " prev, pager, next, jumper, ->, total, slot",
        page: 1,
      }, //秒杀分页
      Goodspaginations: {
        total: "",
        layout: " prev, pager, next, jumper, ->, total, slot",
        page: 1,
      }, //商品分页
      DialogTitle: "", //新增活动弹出层标题
      dialogVisible: false, //新增活动弹出层显隐
      selShopDialogTitle: "", //选择商品弹出层标题
      selShopDialogVisible: false, //选择商品弹出层显隐
      activeName: "0", //弹出层tab索引
      saveType: "",
      ruleForm: {
        tableData: [], //商品
        Price: "", //价格
        activityTime: "", //活动时间
        inventoryBool: false, //库存
        inventory: "", //库存数量
        buyLimitBool: false, //购买上限
        buyLimit: "", //购买上限数量
        restrictBool: false, //活动限制
        checkList: [], //活动限制列表
      },
      rules: {
        tableData: [{ required: true, message: "请选择商品", trigger: "blur" }],
        Price: [{ required: true, message: "请输入秒杀价格", trigger: "blur" }],
        activityTime: [
          { required: true, message: "请输入活动时间", trigger: "change" },
        ],
      },
      defaultProps: {
        children: "Child",
        label: "EntityName",
      },
      entityList: [
        {
          EntityName: "苏州哲联信息科技有限公司",
          IsStore: false,
          IsWarehouse: false,
          ID: 0,
          Child: [
            {
              EntityName: "珠江店",
              IsStore: true,
              IsWarehouse: false,
              ID: 1,
            },
            {
              EntityName: "万达店",
              IsStore: true,
              IsWarehouse: true,
              ID: 2,
            },
          ],
        },
      ],
      OnlineName: "", //线上
      OfflineName: "", //线下
      GoodsType: "", //类型
      CategoryID: "", //分类
      selTableData: [], //商品列表
      GoodType: [], //商品类型
      GoodsCategoryValid: [], //线上分类
      CustomerLevel: [], //顾客等级
    };
  },
  /**计算属性  */
  computed: {},
  /**  方法集合  */
  methods: {
    // 获取秒杀列表
    getSeckill() {
      const that = this;
      let params = {
        PageNum: that.paginations.page,
      };
      let status;
      if (that.couponActive == 1) {
        status = "未开始";
      } else if (that.couponActive == 2) {
        status = "进行中";
      } else if (that.couponActive == 3) {
        status = "已结束";
      }
      if (that.couponName && that.status) {
        params = {
          Title: that.couponName,
          status: status,
          PageNum: that.paginations.page,
        };
      } else if (that.couponName) {
        params.Title = that.couponName;
        params.PageNum = that.paginations.page;
      } else if (status) {
        params.status = status;
        params.PageNum = that.paginations.page;
      }
      API.GetSecKill(params).then((res) => {
        if (res.StateCode == 200) {
          that.tableData = res.List;
          that.paginations.total = res.Total;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    // 获取商品列表
    getGoodAll() {
      const that = this;
      const params = {
        PageNum: this.Goodspaginations.page,
        OfflineName: this.OfflineName,
        GoodsType: this.GoodsType,
        OnlineName: this.OnlineName,
        CategoryID: this.CategoryID[1],
        Active: true,
      };
      API.GoodAll(params).then((res) => {
        if (res.StateCode == 200) {
          that.selTableData = res.List;
          that.Goodspaginations.total = res.Total;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    // 获取商品类型
    getGoodType() {
      API.GoodType().then((res) => {
        if (res.StateCode == 200) {
          this.GoodType = res.Data;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    // 线上分类 - 排除没有子类的分类
    getAppletGoodsCategoryValid() {
      API.AppletGoodsCategoryValid().then((res) => {
        if (res.StateCode == 200) {
          const arr = [];
          res.Data.forEach((res) => {
            const obj = {
              label: res.Name,
              value: res.CategoryID,
              children: [],
            };
            if (res.Child && res.Child.length) {
              res.Child.forEach((res) => {
                obj.children.push({
                  label: res.Name,
                  value: res.CategoryID,
                });
              });
            }

            arr.push(obj);
          });
          this.GoodsCategoryValid = arr;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    // 获取会员等级
    getCustomerLevel() {
      const that = this;
      API.CustomerLevel({}).then((res) => {
        if (res.StateCode == 200) {
          that.CustomerLevel = res.Data;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    dateTimeFormat(row) {
      return (
        dateTime.formatDate.format(
          new Date(row.StartDate),
          "YYYY-MM-DD hh:mm:ss"
        ) +
        "至" +
        dateTime.formatDate.format(new Date(row.EndDate), "YYYY-MM-DD hh:mm:ss")
      );
    },
    // 搜索秒杀列表
    handleSearch() {
      const that = this;
      that.paginations.page = 1;
      that.getSeckill();
    },
    // 搜索商品列表
    selGoodsSearch() {
      this.Goodspaginations.page = 1;
      // 获取商品列表
      this.getGoodAll();
    },
    // 秒杀列表页码改变
    currentChange(e) {
      this.getSeckill();
    },
    // 商品列表分页
    GoodsPageChange() {
      this.getGoodAll();
    },
    // 选择商品弹出层
    selShopClick() {
      this.selShopDialogTitle = "添加指定商品";
      this.selShopDialogVisible = true;
    },
    // 选择商品
    selGoods(row) {
      this.selShopDialogVisible = false;
      this.ruleForm.tableData.push(row);
      this.GoodsBool = false;
      this.$refs.ruleForm.clearValidate("tableData");
    },
    // 删除商品
    deleteGoods() {
      this.ruleForm.tableData = [];
    },
    // 打开弹出层秒杀
    handleSeckill(row, type) {
      const that = this;
      that.dialogVisible = true;
      that.saveType = type;
      switch (type) {
        case "new":
          that.DialogTitle = "新增秒杀活动";
          break;
        case "edit":
          that.DialogTitle = "编辑秒杀活动";
          API.GoodInfo([row.CommodityID]).then((res) => {
            let item = res[0][0];
            const good = {
              Image: [{ ImgURL: item.ImgURL }],
              GoodsTypeName: item.TypeName,
              Price: item.Price,
              OnlineName: item.Name,
            };
            const obj = {
              SeckillID: row.SeckillID,
              tableData: [good], //商品
              Price: row.Price, //价格
              activityTime: [row.StartDate, row.EndDate], //活动时间
              inventoryBool: row.LimitStock, //库存
              inventory: row.limitStockCount, //库存数量
              buyLimitBool: row.LimitBuy, //购买上限
              buyLimit: row.onePersonBuy, //购买上限数量
              restrictBool: row.limitActivity, //活动限制
              checkList: [], //活动限制列表
            };
            if (row.customerLevel) {
              const str = row.customerLevel.slice(
                0,
                row.customerLevel.length - 1
              );
              obj.checkList = str.split(",").map(parseFloat);
            }
            that.ruleForm = obj;
          });
          break;
        case "copy":
          that.DialogTitle = "复制秒杀活动";
          API.GoodInfo([row.CommodityID]).then((res) => {
            let item = res[0][0];
            const good = {
              Image: [{ ImgURL: item.ImgURL }],
              GoodsTypeName: item.TypeName,
              Price: item.Price,
              OnlineName: item.Name,
              GoodsID: item.GoodsID,
            };
            const obj = {
              tableData: [good], //商品
              Price: row.Price, //价格
              activityTime: [row.StartDate, row.EndDate], //活动时间
              inventoryBool: row.LimitStock, //库存
              inventory: row.limitStockCount, //库存数量
              buyLimitBool: row.LimitBuy, //购买上限
              buyLimit: row.onePersonBuy, //购买上限数量
              restrictBool: row.limitActivity, //活动限制
              checkList: [], //活动限制列表
            };
            if (row.customerLevel) {
              const str = row.customerLevel.slice(
                0,
                row.customerLevel.length - 1
              );
              obj.checkList = str.split(",").map(parseFloat);
            }
            that.ruleForm = obj;
          });
          break;
      }
    },
    // 保存处理
    save() {
      const that = this;
      const start = dateTime.formatDate.format(
        new Date(this.ruleForm.activityTime[0]),
        "YYYY-MM-DD hh:mm:ss"
      );
      const end = dateTime.formatDate.format(
        new Date(this.ruleForm.activityTime[1]),
        "YYYY-MM-DD hh:mm:ss"
      );
      console.log(that.ruleForm.tableData[0]);
      const params = {
        StartDate: start, //开始
        EndDate: end, //结束
        CommodityID: that.ruleForm.tableData[0].GoodsID, //商品ID

        Price: that.ruleForm.Price, //价格
        LimitStock: that.ruleForm.inventoryBool, //库存
        limitStockCount: that.ruleForm.inventory, //数量
        LimitBuy: that.ruleForm.buyLimitBool, //购买上限
        onePersonBuy: that.ruleForm.buyLimit, //数量
        limitActivity: that.ruleForm.restrictBool, //活动限制
        listLevel: that.ruleForm.checkList, //限制等级
      };
      if (that.saveType == "edit") {
        params.SeckillID = that.ruleForm.SeckillID;
      }
      const api = that.saveType == "edit" ? "UpdateSecKill" : "AddSecKill";
      API[api](params).then((res) => {
        if (res.StateCode == 200) {
          that.dialogVisible = false;
          that.$message.success({
            message:
              that.saveType == "copy"
                ? "复制成功"
                : that.saveType == "edit"
                ? "修改成功"
                : "添加成功",
            duration: 2000,
          });
          that.handleSearch();
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    // 保存
    confirm() {
      const that = this;
      that.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (that.ruleForm.inventoryBool && !that.ruleForm.inventory) {
            that.$message.error({
              message: "请输入库存数量",
              duration: 2000,
            });
            return;
          }
          if (that.ruleForm.buyLimitBool && !that.ruleForm.buyLimit) {
            that.$message.error({
              message: "请输入购买上限数量",
              duration: 2000,
            });
            return;
          }
          if (that.ruleForm.restrictBool && !that.ruleForm.checkList.length) {
            that.$message.error({
              message: "请选择指定客户等级",
              duration: 2000,
            });
            return;
          }
          that.save();
        }
      });
    },
    // 关闭新增活动弹出层
    dialogClose(type) {
      const that = this;
      that.$refs.ruleForm.resetFields();
      that.ruleForm = {
        tableData: [], //商品
        Price: "", //价格
        activityTime: "", //活动时间
        inventoryBool: false, //库存
        inventory: "", //库存数量
        buyLimitBool: false, //购买上限
        buyLimit: "", //购买上限数量
        restrictBool: false, //活动限制
        checkList: [], //活动限制列表
      };
      that.activeName = "0";
      if (type) {
        that.OnlineName = ""; //线上
        that.OfflineName = ""; //线下
        that.GoodsType = ""; //类型
        that.CategoryID = ""; //分类
        that.Goodspaginations.page = 1;
      }
    },
  },
  /** 监听数据变化   */
  watch: {
    "ruleForm.inventoryBool": function (val, oldVal) {
      if (val == false) {
        this.ruleForm.inventory = "";
      }
    },
    "ruleForm.buyLimitBool": function (val, oldVal) {
      if (val == false) {
        this.ruleForm.buyLimit = "";
      }
    },
    "ruleForm.restrictBool": function (val, oldVal) {
      console.log(val);
      if (val == false) {
        this.ruleForm.checkList = [];
      }
    },
  },
  /** 创建实例之前执行函数   */
  beforeCreate() {},
  /**  实例创建完成之后  */
  created() {},
  /**  在挂载开始之前被调用  */
  beforeMount() {},
  /**  实例被挂载后调用  */
  mounted() {
    // 获取秒杀列表
    this.getSeckill();
    this.getGoodAll();
    // 获取商品类型
    this.getGoodType();
    // 线上分类 - 排除没有子类的分类
    this.getAppletGoodsCategoryValid();
    // 获取会员等级
    this.getCustomerLevel();
  },
  /**  数据更新 之前 调用  */
  beforeUpdate() {},
  /** 数据更新 完成 调用   */
  updated() {},
  /**  实例销毁之前调用  */
  beforeDestroy() {},
  /**  实例销毁后调用  */
  destroyed() {},
};
</script>

<style scoped lang="scss">
.Seckill {
  .el_scrollbar_height {
    height: 60vh;
  }
  .el_scrollbar_height.from {
    height: 50vh;
  }
  .yhForm .el-form-item__label {
    width: 50px;
  }
}
</style>
